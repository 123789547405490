import React from 'react';
import SvgIcon from './SvgIcon';

function BarChartIcon({ width }) {
  return (
    <SvgIcon className="BarChartIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-4.5 -3 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M8.398 17a1 1 0 01-2 0V7a1 1 0 012 0v10zm6 0a1 1 0 01-2 0V1a1 1 0 012 0v16zm-12 0a1 1 0 01-2 0v-4a1 1 0 012 0v4z"
        />
      </svg>
    </SvgIcon>
  );
}

export default BarChartIcon;
