import _ from 'underscore';
import Backbone from 'backbone';

let prototypeMethods;
let InboxFiltersManager;
prototypeMethods = _.extend(Backbone.Events, {
  getSelectedFilters() {
    return _.pick(this.filters, filter => filter.selection.length > 0);
  },
  getFilterSelection() {
    return _.mapObject(this.getSelectedFilters(), filter => filter.selection);
  },
  setFilterSelection(filterName, newSelection) {
    const filter = this.filters[filterName];
    if (undefined !== filter) {
      filter.oldSelection = filter.selection;
      filter.selection = newSelection;
      this.trigger('change', filter);
      this.trigger(`change:${filterName}`, filter, newSelection);
    }
    return this;
  },
  apply() {
    return this.trigger('apply');
  },
  markFiltersAsApplied() {
    return this.trigger('applied');
  },
});
InboxFiltersManager = function() {
  this.filters = {
    accountManager: {
      selection: [],
    },
    country: {
      selection: [],
    },
    vertical: {
      selection: [],
    },
    plan: {
      selection: [],
    },
    signalType: {
      selection: [],
    },
  };
};
InboxFiltersManager.prototype = _.extend(
  InboxFiltersManager.prototype,
  prototypeMethods
);
export default new InboxFiltersManager();
