import _ from 'underscore';
import Backbone from 'backbone';

const VerticalFilter = Backbone.Model.extend({
  noneFilter: {
    label: trovitApp.i18n('criteria_none'),
  },
  verticalValues: [
    {
      label: trovitApp.i18n('g_homes'),
      value: 1,
    },
    {
      label: trovitApp.i18n('g_cars'),
      value: 2,
    },
    {
      label: trovitApp.i18n('g_jobs'),
      value: 4,
    },
    {
      label: trovitApp.i18n('g_products'),
      value: 8,
    },
  ],
  getAllFilters() {
    return _.map(
      _.union([VerticalFilter.noneFilter], VerticalFilter.verticalValues),
      filter => new VerticalFilter(filter)
    );
  },
  getNoneFilter() {
    return new VerticalFilter(VerticalFilter.noneFilter);
  },
});
export default VerticalFilter;
