// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarSubitem {
    padding: 0.97rem 0.858rem 0.97rem  3.9rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--palette-gray-8);
    cursor: pointer;
    text-decoration: none;
    line-height: 1.43rem;
}

.SidebarSubitem:hover {
    color: var(--palette-text);
    background-color: var(--unknown-grey-to-review-dadce0);
    text-decoration: inherit;
}

a.SidebarSubitem {
    text-decoration: inherit;
}

.SidebarSubitem .SvgIcon {
    height: 18px;
    width: 18px;
}

.SidebarSubitem--active, .SidebarSubitem--active:hover {
    color: var(--palette-text);
    background-color: var(--palette-menu-active-bg);
    box-shadow: inset 3px 0px 0px 0px var(--palette-menu-active);
}`, "",{"version":3,"sources":["webpack://./js/components/Sidebar/SidebarSubitem.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,4BAA4B;IAC5B,eAAe;IACf,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;IAC1B,sDAAsD;IACtD,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,+CAA+C;IAC/C,4DAA4D;AAChE","sourcesContent":[".SidebarSubitem {\n    padding: 0.97rem 0.858rem 0.97rem  3.9rem;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    color: var(--palette-gray-8);\n    cursor: pointer;\n    text-decoration: none;\n    line-height: 1.43rem;\n}\n\n.SidebarSubitem:hover {\n    color: var(--palette-text);\n    background-color: var(--unknown-grey-to-review-dadce0);\n    text-decoration: inherit;\n}\n\na.SidebarSubitem {\n    text-decoration: inherit;\n}\n\n.SidebarSubitem .SvgIcon {\n    height: 18px;\n    width: 18px;\n}\n\n.SidebarSubitem--active, .SidebarSubitem--active:hover {\n    color: var(--palette-text);\n    background-color: var(--palette-menu-active-bg);\n    box-shadow: inset 3px 0px 0px 0px var(--palette-menu-active);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
