const availableIso639Languages = [
  'de',
  'en',
  'es',
  'fr',
  'id',
  'it',
  'ja',
  'nl',
  'pl',
  'pt',
  'ru',
  'tr',
];

const importFilesMap = {
  de: 'de_DE',
  en: 'en_GB',
  es: 'es_ES',
  fr: 'fr_FR',
  id: 'id_ID',
  it: 'it_IT',
  ja: 'ja_JP',
  nl: 'nl_NL',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ru: 'ru_RU',
  tr: 'tr_TR',
};

function extractIso639Language(iso3166locale) {
  return iso3166locale.toLowerCase().substring(0, 2);
}

export function getIso639Language(iso3166locale) {
  const lowerCaseLocale = extractIso639Language(iso3166locale);
  if (!availableIso639Languages.includes(lowerCaseLocale)) {
    return 'en';
  }

  return lowerCaseLocale;
}

export function importLocale(locale, importFunction) {
  if (importFunction) {
    return importFunction(
      `i18n/${importFilesMap[getIso639Language(locale)]}.utf8`
    );
  }
  return import(`i18n/${importFilesMap[getIso639Language(locale)]}.utf8`);
}
