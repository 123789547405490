// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#js-support-for-ukraine-banner:not(:empty),
#js-activities-with-russian-partners-stopped-banner:not(:empty),
#js-trovit-data-only-disclaimer-banner:not(:empty)
{
    margin-bottom: 0.7rem;
}

#js-campaigns-settings-alert:not(:empty) ~ .js-campaigns-list {
    margin-top: 95px;
}

#js-activities-with-russian-partners-stopped-banner:not(:empty) ~ .js-campaign-form > .fixed-notification,
#js-activities-with-russian-partners-stopped-banner:not(:empty) ~ .js-campaign-form > #sticky-wrapper
{
    display: none;
}

.button__holder--right{
    display: flex;
    align-items: center;
    justify-content: right;
}
`, "",{"version":3,"sources":["webpack://./js/thribee.css"],"names":[],"mappings":"AAAA;;;;IAII,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;;IAGI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":["#js-support-for-ukraine-banner:not(:empty),\n#js-activities-with-russian-partners-stopped-banner:not(:empty),\n#js-trovit-data-only-disclaimer-banner:not(:empty)\n{\n    margin-bottom: 0.7rem;\n}\n\n#js-campaigns-settings-alert:not(:empty) ~ .js-campaigns-list {\n    margin-top: 95px;\n}\n\n#js-activities-with-russian-partners-stopped-banner:not(:empty) ~ .js-campaign-form > .fixed-notification,\n#js-activities-with-russian-partners-stopped-banner:not(:empty) ~ .js-campaign-form > #sticky-wrapper\n{\n    display: none;\n}\n\n.button__holder--right{\n    display: flex;\n    align-items: center;\n    justify-content: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
