import React from 'react';
import SvgIcon from './SvgIcon';

function DashboardIcon({ width }) {
  return (
    <SvgIcon className="DashboardIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-3 -3 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M1.889 0H6.11A1.89 1.89 0 018 1.889V6.11A1.889 1.889 0 016.111 8H1.89A1.889 1.889 0 010 6.111V1.89A1.89 1.89 0 011.889 0zM2 2v4h4V2H2zm9.889-2h4.222A1.89 1.89 0 0118 1.889V6.11A1.889 1.889 0 0116.111 8H11.89A1.889 1.889 0 0110 6.111V1.89A1.89 1.89 0 0111.889 0zM12 2v4h4V2h-4zm.057 8h4.222a1.89 1.89 0 011.889 1.889v4.222A1.889 1.889 0 0116.279 18h-4.222a1.889 1.889 0 01-1.89-1.889V11.89a1.89 1.89 0 011.89-1.889zm.11 6h4v-4h-4v4zM1.89 9.8h4.22c1.044 0 1.89.844 1.89 1.887v4.223a1.889 1.889 0 01-1.889 1.888H1.89A1.889 1.889 0 010 15.911v-4.223C0 10.645.846 9.8 1.889 9.8zm.111 6h4v-4H2v4z"
        />
      </svg>
    </SvgIcon>
  );
}

export default DashboardIcon;
