import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Loader from '../../components/LoaderImage/Loader';
import 'bootstrap/js/modal';

let Contact;
let ContactUsForm;
Contact = Backbone.Model.extend({
  defaults: {
    subject: '',
    content: '',
  },
  url: '/index.php/cod.contact-us',
  validate(attributes) {
    let errors = {
      content: this.validateProperty('content', attributes.content),
    };
    errors = _.pick(errors, err => typeof err === 'string');
    if (Object.values(errors).length > 0) {
      return errors;
    }
  },
  validateProperty(propertyName, value) {
    value = value || this.get(propertyName);
    switch (propertyName) {
      case 'content':
        if (value.trim() == '') {
          return trovitApp.i18n('error_message_contact_us_form_partners');
        }
      default:
    }
  },
  parse(response, options) {
    response = response.data || {};
    if (_.isEmpty(response)) {
      response = this.__proto__.defaults;
    }
    return Backbone.Model.prototype.parse.call(this, response, options);
  },
  setToDefaults() {
    this.set(this.__proto__.defaults, {
      silent: true,
    });
  },
});
ContactUsForm = Backbone.View.extend({
  events: {
    'submit .js-contact-us-form': 'contactUs',
    'input #contact-us-subject': 'inputSubjectChanged',
    'blur #contact-us-subject': 'inputSubjectChanged',
    'input #contact-us-content': 'inputContentChanged',
    'blur #contact-us-content': 'inputContentChanged',
    'hidden.bs.modal': 'resetForm',
  },
  name: 'contactUsForm',
  loadOrder: 7,
  el: $('#js-contact-us-modal'),
  init() {
    return this;
  },
  initialize() {
    this.ajaxLoader = new Loader({
      el: this.$el.find('.js-contact-us'),
    });
    this.$propertyInputs = {
      subject: this.$('#contact-us-subject'),
      content: this.$('#contact-us-content'),
    };
    this.$success = this.$('.js-contact-us-success');
    this.$errors = this.$('.js-contact-us-error');
    this.$form = this.$('.js-contact-us-form');
    this.model = new Contact({
      subject: this.$propertyInputs.subject.val(),
      content: this.$propertyInputs.content.val(),
    });
    this.model.on(
      {
        invalid: this.contactInvalid,
        request() {
          this.ajaxLoader.on();
        },
        sync: this.contactSaved,
        'sync error': function() {
          this.ajaxLoader.off();
        },
      },
      this
    );
  },
  contactUs(event) {
    event.preventDefault();
    this.model.save();
  },
  showSuccessScreen() {
    this.$errors.hide();
    this.$form.fadeOut(
      'normal',
      $.proxy(function() {
        this.$success.fadeIn('normal');
      }, this)
    );
  },
  showErrorScreen() {
    this.$success.hide();
    this.$form.fadeOut(
      'normal',
      $.proxy(function() {
        this.$errors.fadeIn('normal');
      }, this)
    );
  },
  showFormScreen() {
    this.$success.hide();
    this.$errors.hide();
    this.$form.show();
    return this;
  },
  resetForm() {
    for (const inputName in this.$propertyInputs) {
      this.resetInputValidity(this.$propertyInputs[inputName].val(''));
    }
    this.showFormScreen();
    this.model.setToDefaults();
  },
  setInputCustomValidity($input, error) {
    $input
      .addClass('invalid')
      .get(0)
      .setCustomValidity(error);
    return this;
  },
  resetInputValidity($input) {
    $input
      .removeClass('invalid')
      .get(0)
      .setCustomValidity('');
    return this;
  },
  setContactProperty(propertyName, newValue) {
    this.resetInputValidity(this.$propertyInputs[propertyName]);
    const error = this.model
      .set(propertyName, newValue)
      .validateProperty(propertyName, newValue);
    if (error) {
      this.setInputCustomValidity(this.$propertyInputs[propertyName], error);
    }
    return this;
  },
  inputSubjectChanged(e) {
    this.setContactProperty('subject', e.currentTarget.value);
  },
  inputContentChanged(e) {
    this.setContactProperty('content', e.currentTarget.value);
  },
  contactInvalid(model, errors) {
    for (const propertyName in errors) {
      this.$propertyInputs[propertyName]
        .addClass('invalid')
        .get(0)
        .setCustomValidity(errors[propertyName]);
    }
  },
  contactSaved(model, response) {
    if (response.success) {
      this.showSuccessScreen();
    } else {
      this.showErrorScreen();
    }
  },
});
if (typeof trovitApp !== 'undefined') {
  trovitApp.widgets.loadWidget(new ContactUsForm());
}
export default ContactUsForm;
