/* eslint-disable no-new */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import $ from 'jquery';
import Application from './Application';
import ToggleDisplay from './Views/Element/UI/ToggleDisplay';
import './Managers/MomentAdapter';
import 'bootstrap/js/tooltip';

(function main() {
  $.fn.serializeObject = function serializeObject() {
    const result = {};
    const extend = function(i, element) {
      const node = result[element.name];
      if (typeof node !== 'undefined' && node !== null) {
        if ($.isArray(node)) {
          node.push(element.value);
        } else {
          result[element.name] = [node, element.value];
        }
      } else {
        result[element.name] = element.value;
      }
    };
    $.each(this.serializeArray(), extend);
    return result;
  };
})();
$('[data-toggle="tooltip"]').tooltip({
  html: true,
});
$('.buttons-group').on('click', '.js-select-option', function() {
  $(this)
    .closest('.buttons-group')
    .find('.active')
    .removeClass('active');
  $(this)
    .addClass('active')
    .find('input')
    .prop('checked', true);
});
$('.js-toggle-display').each(function() {
  new ToggleDisplay({
    el: this,
  });
});
window.trovitApp = new Application();
window.trovitApp.start();
export default trovitApp;
