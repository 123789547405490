import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';

const Loader = Backbone.View.extend({
  on(refresh) {
    this.$el.addClass('disabled');
    this.$el.addClass('app_loader_loading');
    this.initialImageLoad();
    if (refresh) {
      this.refresh();
    }
    const opacity = this.settings.wrapperOpacity || 0.6;
    this._imgLoaderWrp.css('opacity', opacity).prependTo(this.$el);
    this._isOn = true;
    return this;
  },
  off() {
    const that = this;
    this.initialImageLoad();
    this._imgLoaderWrp.fadeTo('fast', 0, function() {
      that._imgLoaderWrp.remove();
      that._isOn = false;
      that.$el.removeClass('app_loader_loading');
    });
    that.$el.removeClass('disabled');
    return this;
  },
  initialImageLoad() {
    if (!this._alreadyActivated) {
      this._imgLoader = $(
        '<svg class="svg-icon pulse"><use xlink:href="#icon-spinner"></use></svg>'
      );
      this._imgLoaderWrp = $('<span/>', {
        class: 'loader',
      });
      this.refresh();
      this._imgLoaderWrp.html(this._imgLoader);
      this._alreadyActivated = true;
    }
  },
  _setupWrp() {
    const width = this.$el.innerWidth();
    const height = this.$el.innerHeight();
    if (isNaN(width) || isNaN(height)) {
      return;
    }
    const cssProperties = {
      display: 'inline-block',
      width: `${width}px`,
      height: `${height}px`,
      top: '0px',
      left: '0px',
      position: 'absolute',
      overflow: 'hidden',
      'z-index': this.settings.loaderZindex,
      'background-color': this.settings.backgroundColor,
      opacity: '0.7',
    };
    this._imgLoaderWrp.addClass(this.settings.className);
    this._imgLoaderWrp.css(cssProperties);
  },
  _setupImg() {
    let left = (this._imgLoaderWrp.width() - this.settings.size) / 2;
    const top = (this._imgLoaderWrp.height() - this.settings.size) / 2;
    if (this.settings.align == 'left') {
      left = 0;
    }
    if (isNaN(left) || isNaN(top)) {
      return;
    }
    const cssProperties = {
      position: 'absolute',
      'margin-left': `${left}px`,
      'margin-top': `${top}px`,
      left: '0px',
      top: '0px',
      'font-size': `${this.settings.size}px`,
      color: this.settings.color,
    };
    this._imgLoader.css(cssProperties);
  },
  refresh() {
    this._setupWrp();
    this._setupImg();
  },
  initialize(options) {
    this.settings = {
      size: 30,
      color: '#333',
      loaderZindex: 102,
      backgroundColor: '#fff',
      className: '',
    };
    _.extend(this.settings, options.customSettings);
    this.$el.css('position', 'relative');
    this._isOn = false;
    this._alreadyActivated = false;
    return this;
  },
});
export default Loader;
