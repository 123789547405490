import React from 'react';
import { render } from 'react-dom';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import Button from 'components/library/Button/Button';
import { isCookiePolicyDialogVisibleSelector } from 'components/CookiePolicyDialog/selectors';
import './CookiePolicyDialog.css';
import registerCookiePolicyDialogEvents from './events';

function CookiePolicyDialog({ isVisible }) {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="CookiePolicyDialog">
      {trovitApp.i18n('accept_cookie_policy_intro')}
      <div className="CookiePolicyDialog__footer">
        <a
          href="/index.php/cod.accepted-cookie-policy"
          title="cookie policy document"
        >
          {trovitApp.i18n('accept_cookie_policy_link')}
        </a>
        <div>
          <Button
            color="primary"
            onClick={() => dispatch('COOKIE_POLICY_REJECTED')}
          >
            {trovitApp.i18n('accept_cookie_policy_decline')}
          </Button>
          <Button
            variant="flat"
            color="primary"
            onClick={() => dispatch('COOKIE_POLICY_WAS_ACCEPTED')}
          >
            {trovitApp.i18n('accept_cookie_policy_accept')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const SubscribedCookiePolicyDialog = subscribe(
  CookiePolicyDialog,
  function mapStateToProps(state) {
    return {
      isVisible: isCookiePolicyDialogVisibleSelector(state),
    };
  }
);

export default function cookiePolicyDialog() {
  const element = document.getElementById('js-cookie-policy-dialog');
  if (!element) {
    return;
  }

  registerCookiePolicyDialogEvents();
  dispatch('INITIALIZE_COOKIE_POLICY_DIALOG');

  render(
    <SubscribedCookiePolicyDialog />,
    document.getElementById('js-cookie-policy-dialog')
  );
}
