import $ from 'jquery';
import Backbone from 'backbone';

const ToggleDisplay = Backbone.View.extend({
  events: {
    'click .js-toggle-element': 'manageClick',
  },
  manageClick(event) {
    const target = $(event.currentTarget);
    const action = target.data('toggle-action');
    const triggerAction = target.data('toggle-trigger-action');
    const triggerTarget = target.data('toggle-trigger-target');
    const iteratorIcon = this.$el.find('.js-toggle-element svg>use');
    if (action == 'show') {
      this.doShow();
    } else if (action == 'hide') {
      this.doHide();
    } else {
      this.doToggle();
    }
    if (
      iteratorIcon.length &&
      iteratorIcon.attr('xlink:href') == '#icon-caret-up'
    ) {
      iteratorIcon.attr('xlink:href', '#icon-caret-down');
    } else if (
      iteratorIcon.length &&
      iteratorIcon.attr('xlink:href') == '#icon-caret-down'
    ) {
      iteratorIcon.attr('xlink:href', '#icon-caret-up');
    }
    if (triggerAction && triggerTarget) {
      $(triggerTarget).trigger(triggerAction);
    }
  },
  doShow() {
    this.toggledElement.slideDown('fast');
    this.$el.find('.js-toggle-element').addClass(this.activeClass);
    this.toggledElement.addClass(this.activeClass);
  },
  doHide() {
    this.toggledElement.slideUp('fast');
    this.$el.find('.js-toggle-element').removeClass(this.activeClass);
    this.toggledElement.removeClass(this.activeClass);
  },
  doToggle() {
    this.toggledElement.slideToggle('fast');
    if (this.activeClass) {
      this.$el.find('.js-toggle-element').toggleClass(this.activeClass);
      this.toggledElement.toggleClass(this.activeClass);
    }
  },
  initialize() {
    this.activeClass = this.$el.data('toggle-active-class');
    this.toggledElement = this.$el.data('toggle-target')
      ? (this.toggledElement = $(this.$el.data('toggle-target')))
      : this.$el.children('.js-toggled-element');
  },
});
export default ToggleDisplay;
