import $ from 'jquery';
import LoaderImage from './LoaderImage';

const AjaxLoaderImage = LoaderImage.extend({
  jqXHRs: [],
  addJqXHR(jqXhr) {
    const xhrStatus = jqXhr.state();
    this.jqXHRs.push(jqXhr);
    if (this.running === false && xhrStatus === 'pending') {
      this.show();
    }
    jqXhr
      .always(
        $.proxy(function() {
          const index = this.jqXHRs.indexOf(jqXhr);
          if (index > -1) {
            this.jqXHRs.splice(index, 1);
          }
          if (this.jqXHRs.length === 0) {
            this.hide();
          }
        }, this)
      )
      .fail(
        $.proxy(function() {
          this.jqXHRs.forEach(function(jqXhrItem) {
            if (jqXhrItem.reject) {
              jqXhrItem.reject();
            } else if (jqXhrItem.abort) {
              jqXhrItem.abort();
            } else {
              console.warn('Unknown XHR object');
            }
          });
          this.jqXHRs = [];
        }, this)
      );
  },
});
export default AjaxLoaderImage;
