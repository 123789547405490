import _ from 'underscore';
import Backbone from 'backbone';
import tplVerticalFilter from '../../templates/views/accountManagerInbox/accountManagerVerticalFilter.ejs';

const VerticalFilterView = Backbone.View.extend({
  template: _.template(tplVerticalFilter),
  name: 'VerticalFilterView',
  loadOrder: 9,
  events: {
    'click .vertical-selector-option': 'verticalOptionChanged',
  },
  initialize() {
    this.collection = new Backbone.Collection([
      {
        label: trovitApp.i18n('criteria_none'),
        id: 0,
        selected: true,
      },
      {
        label: trovitApp.i18n('g_homes'),
        id: 1,
      },
      {
        label: trovitApp.i18n('g_cars'),
        id: 2,
      },
      {
        label: trovitApp.i18n('g_jobs'),
        id: 4,
      },
      {
        label: trovitApp.i18n('g_products'),
        id: 8,
      },
    ]);
  },
  render() {
    this.$el.html(
      this.template({
        verticalList: this.collection,
      })
    );
    return this;
  },
  verticalOptionChanged(event) {
    this.$('.selected-option').html(event.currentTarget.text);
    const currentValue = parseInt(event.currentTarget.dataset.value);
    const previousSelectedModel = this.collection.findWhere({
      selected: true,
    });
    const selectedModel = this.collection.findWhere({
      id: currentValue,
    });
    if (previousSelectedModel !== selectedModel) {
      this.collection.forEach(model =>
        model.set('selected', false, {
          silent: true,
        })
      );
      selectedModel.set('selected', true);
    }
  },
});
export default VerticalFilterView;
