import _s from 'underscore.string';
import Backbone from 'backbone';
import tplSource from '../../templates/views/accountManagerInbox/sourceSignal.ejs';

const SourceSignalView = Backbone.View.extend({
  tagName: 'tr',
  template: _.template(tplSource),
  name: 'sourceSignalView',
  events: {},
  loadOrder: 9,
  render() {
    this.$el.html(
      this.template({
        sourceSignal: this.model,
        _s,
      })
    );
    return this;
  },
});
export default SourceSignalView;
