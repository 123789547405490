import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import 'bootstrap/js/dropdown';

const Dropdown = Backbone.View.extend({
  events: {
    'click .dropdown-menu input[type=checkbox]': 'checkboxClicked',
    'click .block-click-outside': function(e) {
      e.stopPropagation();
    },
    'keyup input[name=search]': 'filterSearchKeyPressed',
    'click li a.dropdown-item': 'dropdownItemClicked',
    'change .dropdown-item input[type=checkbox]': 'dropdownItemClicked',
    'click .js-all': function(e) {
      if (e) e.preventDefault();
      this.selection = this.$dropdownOptions
        .find('input[type=checkbox]')
        .prop('checked', true)
        .map((index, checkbox) => checkbox.value);
      this.updateDropdownToggleSpanMultipleText();
      this.updatParentSelection();
    },
    'click .js-none': function(e) {
      if (e) e.preventDefault();
      this.selection = [];
      this.$dropdownOptions.find('input[type=checkbox]').prop('checked', false);
      this.updateDropdownToggleSpanMultipleText();
      this.updatParentSelection();
    },
  },
  template: _.template(`<div class="dropdown" data-trovit-dropdown-title="<%- title %>">
  <button class="btn btn-link dropdown-toggle" type="button" id="<%- uniqueId %>" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <span><%= title %></span><i class="material-icons">arrow_drop_down</i></button>
  
  <div class="dropdown-menu" aria-labelledby="<%- uniqueId %>" role="menu">
    <div class="block-click-outside">
      <input type="text" placeholder="<%- trovitApp.i18n('js_search') %>" name="search"<br>
      <% if (isMultiple) { %>
      <div class="links">
        <a class="js-all" href="#"><%- trovitApp.i18n("js_all") %></a>
        <a class="js-none" href="#"><%- trovitApp.i18n("js_none") %></a>
      </div>
      <% } %>
      <ul class="box-content"></ul>
    </div> 
  </div>  
</div>`),
  templateOption: _.template(`<li>
  <% if (isMultiple) { %>
    <label class="dropdown-item" data-value="<%- value %>">
        <input type="checkbox" value="<%- value %>" <% if (isChecked) { %>checked="checked"<% }%>> <%- text %>
    </label>
  <% } else {%>
    <a class="dropdown-item" data-value="<%- value %>"><%- text %></a>
  <% } %>
</li>`),
  constructor: function dropdownConstructor(element, options) {
    this.$parentElement = element;
    Backbone.View.call(this, options);
  },
  initialize() {
    this.selection =
      this.$parentElement.val() ||
      (this.$parentElement.prop('multiple') ? [] : null);
    if (Array.isArray(this.selection)) {
      this.selection = this.selection.map(val => parseInt(val) || val);
    }
  },
  render() {
    const isMultiple = this.$parentElement.prop('multiple');
    this.$parentElement.addClass('hide');
    this.setElement(
      $(
        this.template({
          title: this.$parentElement.attr('title'),
          uniqueId: this.cid,
          isMultiple,
        })
      )
    );
    this.$dropdownToggleSpan = this.$('.dropdown-toggle span');
    this.$dropdownOptions = this.$(
      'ul.dropdown-menu, .dropdown-menu ul.box-content'
    );
    this.renderDropdownOptions();
    if (isMultiple) {
      this.updateDropdownToggleSpanMultipleText();
    }
    this.$parentElement.next('.dropdown').remove();
    this.$parentElement.after(this.$el);
    return this;
  },
  renderDropdownOptions() {
    let dropdownItems;
    dropdownItems = this.$parentElement.children().map(
      $.proxy(function(index, option) {
        const value = parseInt(option.value) || option.value;
        const color = option.dataset.color || '';
        const text = option.innerText;
        let anchor;
        anchor = $(
          this.templateOption({
            value,
            color,
            text,
            isChecked:
              (Array.isArray(this.selection) &&
                this.selection.includes(value)) ||
              _.isEqual(this.selection, [true]),
            isMultiple: this.$parentElement.prop('multiple'),
            uniqueId: this.cid,
          })
        );
        if (this.$parentElement.get(0).value == value) {
          this.$dropdownToggleSpan.html(anchor.find('a').html());
        }
        return anchor;
      }, this)
    );
    this.$dropdownOptions.append(dropdownItems.toArray());
  },
  remove() {
    this.$parentElement.removeData('trovit_dropdown', '').removeClass('hide');
    return Backbone.View.prototype.remove.apply(this, arguments);
  },
  toggle() {
    this.$('.dropdown-toggle').dropdown('toggle');
    return this;
  },
  checkboxClicked(e) {
    const checboxes = this.$(
      'ul.dropdown-menu input[type=checkbox], .dropdown-menu ul.box-content input[type=checkbox]'
    );
    const checked = checboxes.filter(':checked');
    if (checked.length === 0) {
      checked.prop('checked', 'checked');
    }
  },
  filterSearchKeyPressed(e) {
    const filterSearch = e.target.value.split(/\s+/);
    let searchRegex;
    if (filterSearch.length === 0) return;
    searchRegex = new RegExp(`(?=.*${filterSearch.join(')(?=.*')})`, 'i');
    this.$dropdownOptions
      .children()
      .detach()
      .each((index, li) =>
        $(li).toggleClass('hide', !searchRegex.test(li.innerText))
      )
      .appendTo(this.$dropdownOptions);
  },
  dropdownItemClicked(event) {
    const isMultiple = this.$parentElement.prop('multiple');
    if (event) {
      event.preventDefault();
      if (isMultiple) event.stopPropagation();
    }
    if (!isMultiple) {
      this.$dropdownToggleSpan.html(event.target.innerText);
      this.selection =
        parseInt(event.target.dataset.value) || event.target.dataset.value;
      this.updatParentSelection();
      return;
    }
    this.updateDropdownToggleSpanMultipleText();
    this.selection = this.$dropdownOptions
      .find('input:checked')
      .map((index, el) => parseInt(el.value) || el.value)
      .toArray();
    this.updatParentSelection();
  },
  updatParentSelection() {
    this.$parentElement.val(this.selection).trigger('change');
    return this;
  },
  updateDropdownToggleSpanMultipleText() {
    let checked;
    let toggleSpanText;
    checked = this.$dropdownOptions.find('input:checked');
    if (checked.length > 0) {
      if (
        this.$dropdownOptions.find('input[type=checkbox]').length ===
        checked.length
      ) {
        toggleSpanText = `${this.$parentElement.attr(
          'title'
        )} (${trovitApp.i18n('js_all')})`;
      } else {
        toggleSpanText = `${this.$parentElement.attr('title')} (${
          checked.length
        })`;
      }
    } else {
      toggleSpanText = `${this.$parentElement.attr('title')}`;
    }
    this.$dropdownToggleSpan.html(toggleSpanText);
    return this;
  },
});
$.fn.trovit_dropdown = function(options) {
  const implementOptions = $.extend(
    true,
    {},
    $.fn.trovit_dropdown.defaultOptions,
    options
  );
  this.each(function() {
    const el = $(this);
    if (el.data('trovit_dropdown')) el.data('trovit_dropdown').remove();
    el.data(
      'trovit_dropdown',
      new Dropdown(el, $.extend({}, implementOptions)).render()
    );
  });
  return this;
};
export default Dropdown;
