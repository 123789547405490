import _ from 'underscore';
import Backbone from 'backbone';

const LoaderImage = Backbone.View.extend({
  templateLoadingMessage: _.template(`<div id="t-loader"><div id="t-shadow"></div><div id="t-box"></div><div class="t-text">
    <%= trovitApp.i18n('js_loading_data') %>
</div></div>`),
  templateWarningMessage: _.template(
    `<svg class="svg-icon"><use xlink:href="#icon-warning"></use></svg>&nbsp;<%= message %>`
  ),
  initialize() {
    this.running = false;
    this.finished = false;
  },
  show() {
    if (this.running) {
      return this;
    }
    this.$el.fadeIn('fast');
    this.setLoadingMessage();
    this.running = true;
    this.finished = false;
    return this;
  },
  hide() {
    this.$el.fadeOut('fast');
    this.running = false;
    this.finished = true;
    this.trigger('hidden');
    return this;
  },
  setLoadingMessage() {
    this.setMessage(this.templateLoadingMessage(), 'info');
    return this;
  },
  setWarningMessage(message) {
    this.setMessage(
      this.templateWarningMessage({
        message,
      }),
      'warning'
    );
    return this;
  },
  setMessage(message, messageType) {
    let textClass;
    let textClassesToRemove;
    switch (messageType) {
      case 'info':
        textClass = 'text-info';
        textClassesToRemove = 'text-danger text-warning';
        break;
      case 'warning':
        textClass = 'text-warning';
        textClassesToRemove = 'text-danger text-info';
        break;
      case 'danger':
        textClass = 'text-danger';
        textClassesToRemove = 'text-info';
        break;
    }
    this.$('h3')
      .html(message)
      .removeClass(textClassesToRemove)
      .addClass(textClass);
    return this;
  },
});
export default LoaderImage;
