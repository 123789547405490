import './SidebarCollapseButton.css';
import React from 'react';
import PaginationLeftIcon from 'components/library/Icons/PaginationLeftIcon';
import PaginationRightIcon from 'components/library/Icons/PaginationRightIcon';

const ICON_WIDTH = 24;

function SidebarCollapseButton({ collapsed, onClick }) {
  return (
    <button type="button" className="SidebarCollapseButton" onClick={onClick}>
      {collapsed ? (
        <PaginationRightIcon width={ICON_WIDTH} />
      ) : (
        <PaginationLeftIcon width={ICON_WIDTH} />
      )}
    </button>
  );
}

export default SidebarCollapseButton;
