import React from 'react';
import SvgIcon from './SvgIcon';

function ClockIcon({ width }) {
  return (
    <SvgIcon className="ClockIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-1.6 -2 22 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M9.398 18a9 9 0 110-18 9 9 0 010 18zm0-2a7 7 0 100-14 7 7 0 000 14zm1-10.8v3.182l2.648 1.324a1 1 0 01-.895 1.788l-3.2-1.6A1 1 0 018.398 9V5.2a1 1 0 012 0z"
        />
      </svg>
    </SvgIcon>
  );
}

export default ClockIcon;
