import moment from 'moment';
import { getIso639Language } from 'utils/locales';

const locale = getIso639Language(trovitData.localeISO3166);
const notSoShortFormatLocales = ['es', 'pt'];
let replacementRegex;
let shortFormat;
moment.locale(locale);
if (notSoShortFormatLocales.includes(locale)) {
  switch (locale) {
    case 'es':
    case 'pt':
      replacementRegex = / \[de] /g;
      break;
    default:
  }
  shortFormat = moment
    .localeData()
    .longDateFormat('ll')
    .replace(replacementRegex, ' ');
  moment.updateLocale(locale, {
    longDateFormat: {
      ll: shortFormat,
    },
  });
}
export default moment;
