import _ from 'underscore';
import Backbone from 'backbone';

const SignalFilter = Backbone.Model.extend(
  {
    toJSON(options) {
      return {
        type: this.get('type'),
        value: this.get('value'),
      };
    },
  },
  {
    noneFilter: {
      label: 'All Clients',
    },
    signalValues: [
      {
        type: 'signalType',
        label: 'Blocked by budget',
        value: 1,
      },
      {
        type: 'signalType',
        label: 'Underdelivery',
        value: 2,
      },
      {
        type: 'signalType',
        label: 'Delivery OK',
        value: 3,
      },
      {
        type: 'signalType',
        label: 'Traffic drop',
        value: 4,
      },
      {
        type: 'signalType',
        label: 'No active campaigns',
        value: 5,
      },
      {
        type: 'plan',
        label: 'Golden clients',
        value: 5,
      },
      {
        type: 'plan',
        label: 'Silver clients',
        value: 3,
      },
      {
        type: 'plan',
        label: 'Free clients',
        value: 1,
      },
    ],
    getAllFilters() {
      return _.map(
        _.union([SignalFilter.noneFilter], SignalFilter.signalValues),
        filter => new SignalFilter(filter)
      );
    },
    getNoneFilter() {
      return new SignalFilter(SignalFilter.noneFilter);
    },
  }
);
export default SignalFilter;
