// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CookiePolicyDialog {
  border-radius: 0.57rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--palette-thribee-white);
  padding: 1.7rem;
  z-index: var(--z-index-high2);
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: 740px;
  bottom: 5rem;
}

.CookiePolicyDialog__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.CookiePolicyDialog__footer a {
  text-decoration: underline;
}

.CookiePolicyDialog__footer .Button {
  margin-left: 0.7rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/CookiePolicyDialog/CookiePolicyDialog.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,4CAA4C;EAC5C,8CAA8C;EAC9C,eAAe;EACf,6BAA6B;EAC7B,eAAe;EACf,cAAc;EACd,OAAO;EACP,QAAQ;EACR,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".CookiePolicyDialog {\n  border-radius: 0.57rem;\n  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);\n  background-color: var(--palette-thribee-white);\n  padding: 1.7rem;\n  z-index: var(--z-index-high2);\n  position: fixed;\n  margin: 0 auto;\n  left: 0;\n  right: 0;\n  max-width: 740px;\n  bottom: 5rem;\n}\n\n.CookiePolicyDialog__footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 1rem;\n}\n\n.CookiePolicyDialog__footer a {\n  text-decoration: underline;\n}\n\n.CookiePolicyDialog__footer .Button {\n  margin-left: 0.7rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
