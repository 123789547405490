import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals } from 'reffects-batteries';

export default function registerEvents() {
  registerEventHandler(
    'INITIALIZE_SIDEBAR',
    function initializeSidebar({
      globals: {
        trovitData: { currentSidebarItem, sidebarConfiguration = {} },
      },
    }) {
      const sidebar = Object.entries(sidebarConfiguration).map(
        ([
          key,
          { href: url, name: text, icon, children: subItems, dataTarget },
        ]) => {
          const hasAnActiveSubItem = checkHasAnActiveSubItem(
            subItems,
            currentSidebarItem
          );
          const active = currentSidebarItem === key || hasAnActiveSubItem;

          return {
            key,
            url,
            text,
            icon,
            dataTarget,
            active,
            subItems: extractSubItems(subItems, currentSidebarItem),
          };
        }
      );

      return {
        ...state.set({
          sidebar,
        }),
      };
    },
    [globals.get('trovitData')]
  );
}

function checkHasAnActiveSubItem(subItems, currentSidebarItem) {
  return (
    subItems &&
    Object.entries(subItems).some(
      ([subItemKey]) => currentSidebarItem === subItemKey
    )
  );
}

function extractSubItems(subItems, currentSidebarItem) {
  if (subItems == null) {
    return undefined;
  }

  return Object.entries(subItems).map(
    ([key, { href: url, name: text, icon, dataTarget }]) => ({
      key,
      url,
      text,
      icon,
      dataTarget,
      active: currentSidebarItem === key,
    })
  );
}
