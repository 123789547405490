import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import registerSidebarEvents from './events';
import SidebarSubitem from './SidebarSubitem';
import SidebarItem from './SidebarItem';
import Sidebar from './Sidebar';
import DashboardIcon from '../library/Icons/DashboardIcon';
import BarChartIcon from '../library/Icons/BarChartIcon';
import UserIcon from '../library/Icons/UserIcon';
import ClockIcon from '../library/Icons/ClockIcon';
import HelpIcon from '../library/Icons/HelpIcon';
import HeartIcon from '../library/Icons/HeartIcon';
import SidebarCollapseButton from './SidebarCollapseButton';

const iconMap = new Map()
  .set('dashboard', <DashboardIcon />)
  .set('trending_up', <BarChartIcon />)
  .set('group', <UserIcon />)
  .set('history', <ClockIcon />)
  .set('help', <HelpIcon />)
  .set('feedback', <HeartIcon />);

function openModal(dataTarget) {
  $(dataTarget).modal('show');
}

function SidebarSubitems({ subItems }) {
  return subItems.map(({ key, text, url, active }) => (
    <SidebarSubitem key={`sidebar-subitem-${key}`} url={url} active={active}>
      {text}
    </SidebarSubitem>
  ));
}

function SidebarBuilder({ items }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Sidebar shrunk={isCollapsed}>
      {items.map(({ key, text, url, icon, subItems, dataTarget, active }) => {
        const [isOpen, setIsOpen] = useState(active);
        const shouldShowSubitems = !isCollapsed && isOpen && subItems != null;

        function onClickItem() {
          if (dataTarget != null) {
            return openModal(dataTarget);
          }

          if (!isCollapsed) {
            return setIsOpen(!isOpen);
          }

          if (url == null) {
            setIsCollapsed(false);
          }

          return setIsOpen(true);
        }

        return (
          <React.Fragment key={`sidebar-item-${key}`}>
            <SidebarItem url={url} active={active} onClick={onClickItem}>
              {iconMap.get(icon)}
              {isCollapsed ? null : <span>{text}</span>}
            </SidebarItem>
            {shouldShowSubitems && <SidebarSubitems subItems={subItems} />}
          </React.Fragment>
        );
      })}
      <SidebarCollapseButton
        collapsed={isCollapsed}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      />
    </Sidebar>
  );
}

const SubscribedSidebarBuilder = subscribe(SidebarBuilder, (state) => ({
  items: state.sidebar,
}));

export default function sideBar() {
  const sidebarElement = document.getElementById('js-thribee-menu');
  if (!sidebarElement) {
    return;
  }

  registerSidebarEvents();
  dispatch('INITIALIZE_SIDEBAR');

  ReactDOM.render(<SubscribedSidebarBuilder />, sidebarElement);
}
