import React from 'react';
import SvgIcon from './SvgIcon';

export default function PaginationLeftIcon({ width }) {
  return (
    <SvgIcon className="PaginationLeftIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
      >
        <path id="a" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </SvgIcon>
  );
}
