import _ from 'underscore';
import Backbone from 'backbone';
import filtersManager from '../../Managers/AccountManagerInbox/FiltersManager';
import SourceSignal from './SourceSignal';

const SourceSignalCollection = Backbone.Collection.extend({
  model: SourceSignal,
  url: '/index.php/cod.account-manager-home-signals-json',
  initialize() {
    this.listenTo(filtersManager, 'apply', this.filtersApplying);
    this.on('sync', filtersManager.markFiltersAsApplied, filtersManager);
    return this;
  },
  parse(response, options) {
    if (response.hasOwnProperty('data')) {
      response = response.data;
    }
    return Backbone.Collection.prototype.parse.call(this, response, options);
  },
  fetch(options) {
    options = _.extendOwn(
      {
        data: filtersManager.getFilterSelection(),
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        method: 'GET',
      },
      options
    );
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
  setSignalFilter(signalFilter) {
    this.signalFilter = signalFilter;
    switch (signalFilter.get('type')) {
      case 'plan':
        filtersManager.setFilterSelection('signalType', []);
        break;
      case 'signalType':
        filtersManager.setFilterSelection('plan', []);
        break;
      case undefined:
        filtersManager
          .setFilterSelection('signalType', [])
          .setFilterSelection('plan', []);
        break;
    }
    filtersManager
      .setFilterSelection(
        signalFilter.get('type'),
        _.flatten([signalFilter.get('value')])
      )
      .apply();
    return this;
  },
  filtersApplying() {
    return this.fetch();
  },
});
export default SourceSignalCollection;
