import React from 'react';
import './Sidebar.css';
import composeClassNames from 'utils/composeClassNames';

function Sidebar({ children, shrunk }) {
  const classNames = composeClassNames([
    'Sidebar',
    shrunk ? 'Sidebar--collapsed' : null,
  ]);
  return <nav className={classNames}>{children}</nav>;
}

export default Sidebar;
