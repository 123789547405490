import React from 'react';
import SvgIcon from './SvgIcon';

function HelpIcon({ width }) {
  return (
    <SvgIcon className="HelpIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-1.6 -2 22 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M9.398 18a9 9 0 110-18 9 9 0 010 18zm0-2a7 7 0 100-14 7 7 0 000 14zm2.432-8.686s.069 1.014-1.077 1.701c-.296.178-.398.56-.427.836a.145.145 0 01-.144.13H8.597a.144.144 0 01-.144-.16c.042-.376.243-1.313 1.208-1.787.208-.102.53-.263.583-.72.055-.473-.367-.9-.865-.864-.35.024-.654.214-.789.512a.145.145 0 01-.132.087H7.11a.144.144 0 01-.143-.168 2.45 2.45 0 014.863.433zM10.18 13H8.594a.144.144 0 01-.144-.144V11.27c0-.08.065-.144.144-.144h1.586c.08 0 .144.065.144.144v1.586c0 .08-.064.144-.144.144z"
        />
      </svg>
    </SvgIcon>
  );
}

export default HelpIcon;
