// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes animate-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.SvgIcon {
  line-height: 0;
  display: inline-block;
}

.SvgIcon--spin svg {
  -webkit-animation: animate-spin 1s infinite steps(8);
  animation: animate-spin 1s infinite steps(8);
}
`, "",{"version":3,"sources":["webpack://./js/components/library/Icons/SvgIcon.css"],"names":[],"mappings":"AAAA;EACE;IACE,+BAA+B;IAC/B,2BAA2B;IAC3B,uBAAuB;EACzB;EACA;IACE,iCAAiC;IACjC,6BAA6B;IAC7B,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,oDAAoD;EACpD,4CAA4C;AAC9C","sourcesContent":["@keyframes animate-spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n    -ms-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(359deg);\n    -ms-transform: rotate(359deg);\n    transform: rotate(359deg);\n  }\n}\n\n.SvgIcon {\n  line-height: 0;\n  display: inline-block;\n}\n\n.SvgIcon--spin svg {\n  -webkit-animation: animate-spin 1s infinite steps(8);\n  animation: animate-spin 1s infinite steps(8);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
