import $ from 'jquery';
import _ from 'underscore';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/effect';
import 'bootstrap/js/tooltip';
import Backbone from 'backbone';
import SourceSignalView from './SourceSignalView';
import SourceSignalCollection from '../../Models/AccountManagerInbox/SourceSignalCollection';
import SignalFilterView from './SignalFilterView';
import SignalFilter from '../../Models/AccountManagerInbox/SignalFilter';
import tplSource from '../../templates/views/accountManagerInbox/accountManagerInboxTable.ejs';

const AccountManagerInboxTable = Backbone.View.extend({
  template: _.template(tplSource),
  name: 'accountManagerInboxTable',
  el: $('#js-account-manager-inbox'),
  events: {},
  loadOrder: 9,
  initialize(options) {
    this.subViews = {};
    this.ajaxLoaderImage = options.ajaxLoaderImage;
    this.collection = new SourceSignalCollection();
    this.collection
      .on(
        {
          request() {
            this.ajaxLoaderImage.addJqXHR(arguments[1]);
          },
          sync: this.render,
        },
        this
      )
      .fetch();
    return true;
  },
  render() {
    this.$el.html(
      this.template({
        signalFilter:
          this.collection.signalFilter || SignalFilter.getNoneFilter(),
      })
    );
    this.$('[data-toggle="tooltip"]').tooltip({
      html: true,
    });
    this.renderFilters();
    this.renderSignals();
  },
  renderFilters() {
    this.subViews.signalFilters = _.map(SignalFilter.getAllFilters(), filter =>
      new SignalFilterView({
        model: filter,
        signalsCollection: this.collection,
      }).render()
    );
    this.$('.js-signals-filters').append(
      ...this.subViews.signalFilters.map(subView => subView.$el)
    );
  },
  renderSignals() {
    this.subViews.sourceSignals = this.collection.map(sourceSignal =>
      new SourceSignalView({
        model: sourceSignal,
      }).render()
    );
    this.$('.js-source-signals').append(
      ...this.subViews.sourceSignals.map(subView => subView.$el)
    );
  },
});
export default AccountManagerInboxTable;
