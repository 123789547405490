import React from 'react';
import composeClassNames from '../../utils/composeClassNames';
import './SidebarSubitem.css';

function SidebarSubitem({ children, url, active }) {
  const classNames = composeClassNames([
    'SidebarSubitem',
    active ? 'SidebarSubitem--active' : null,
  ]);
  return (
    <a className={classNames} href={url}>
      {children}
    </a>
  );
}

export default SidebarSubitem;
