import React from 'react';
import SvgIcon from './SvgIcon';

function HeartIcon({ width }) {
  return (
    <SvgIcon className="HeartIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-1.6 -2.5 22 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M13.187 0c-1.398 0-2.733.595-3.71 1.645l-.079.085-.079-.085c-2.04-2.193-5.38-2.193-7.42 0-2.001 2.151-2.001 5.612 0 7.763l6.767 7.273a1 1 0 001.464 0l6.767-7.273c.964-1.035 1.501-2.43 1.501-3.881 0-1.451-.537-2.847-1.501-3.882C15.92.595 14.585 0 13.187 0zM3.364 8.046c-1.287-1.384-1.287-3.655 0-5.039 1.249-1.342 3.242-1.342 4.49 0l.812.872a1 1 0 001.464 0l.812-.872C11.544 2.36 12.352 2 13.187 2c.836 0 1.643.36 2.245 1.007.617.662.966 1.57.966 2.52s-.35 1.857-.965 2.519l-.812.872-5.223 5.614-6.034-6.486z"
        />
      </svg>
    </SvgIcon>
  );
}

export default HeartIcon;
