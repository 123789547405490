import React from 'react';
import SvgIcon from './SvgIcon';

function UserIcon({ width }) {
  return (
    <SvgIcon className="UserIcon" width={width}>
      <svg
        width="100%"
        height="100%"
        viewBox="-3 -2.1 25 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M18.398 19a1 1 0 01-2 0v-2a3 3 0 00-3-3h-8a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5h8a5 5 0 015 5v2zm-9-9a5 5 0 110-10 5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6z"
        />
      </svg>
    </SvgIcon>
  );
}

export default UserIcon;
