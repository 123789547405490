import _ from 'underscore';
import Backbone from 'backbone';
import filtersManager from '../../Managers/AccountManagerInbox/FiltersManager';
import Dropdown from '../Element/UI/Dropdown';
import VerticalFilterView from './VerticalFilterView';
import VerticalFilterModel from '../../Models/AccountManagerInbox/VerticalFilter';

const TopBarFilters = Backbone.View.extend({
  events: {
    'change .js-account-manager-filter-selector select':
      'accountManagerSelectChanged',
    'change .js-country-filter-selector select': 'countrySelectChanged',
    'click .js-apply-filters': 'applyFiltersClicked',
  },
  initialize() {
    this.subViews = {
      accountManagerDropdown: new Dropdown(
        this.$('.js-account-manager-filter-selector select')
      ),
      countryDropdown: new Dropdown(
        this.$('.js-country-filter-selector select')
      ),
      verticalDropdown: new VerticalFilterView({
        el: this.$('.js-vertical-filter-selector'),
        model: VerticalFilterModel,
      }),
    };
    this.listenTo(filtersManager, 'change', this.filtersChanged)
      .listenTo(filtersManager, 'applied', this.filtersApplied)
      .listenTo(
        this.subViews.verticalDropdown.collection,
        'change:selected',
        this.verticalSelectChanged
      );
  },
  render() {
    this.$applyButton = this.$('.js-apply-filters');
    this.$filters = {
      accountManager: this.$('.js-account-manager-filter-selector select'),
      country: this.$('.js-country-filter-selector select'),
      vertical: this.$('.js-vertical-filter-selector'),
    };
    this.setInitialFilterValues();
    this.subViews.accountManagerDropdown.render();
    this.subViews.countryDropdown.render();
    this.subViews.verticalDropdown.render();
    return this;
  },
  setInitialFilterValues() {
    this.$filters.accountManager.val('');
    this.$filters.country.val('');
    this.$filters.vertical.val('');
  },
  accountManagerSelectChanged(e) {
    filtersManager.setFilterSelection(
      'accountManager',
      _.compact([e.target.value])
    );
    this.subViews.accountManagerDropdown.toggle();
    return this;
  },
  countrySelectChanged(e) {
    filtersManager.setFilterSelection('country', _.compact([e.target.value]));
    this.subViews.countryDropdown.toggle();
    return this;
  },
  verticalSelectChanged(model) {
    if (model.get('id')) {
      filtersManager.setFilterSelection(
        'vertical',
        _.compact([model.get('id')])
      );
    } else {
      filtersManager.setFilterSelection('vertical', _.compact([]));
    }
  },
  filtersChanged() {
    this.$applyButton.removeClass('disabled').prop('disabled', false);
    return this;
  },
  filtersApplied() {
    this.$applyButton.addClass('disabled').prop('disabled', true);
  },
  applyFiltersClicked(e) {
    e.preventDefault();
    filtersManager.apply();
  },
});
export default TopBarFilters;
