import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { cookies, globals, http } from 'reffects-batteries';

export default function registerCookiePolicyDialogEvents() {
  registerEventHandler(
    'INITIALIZE_COOKIE_POLICY_DIALOG',
    function initializeCookiePolicyDialog() {
      return {
        ...http.get({
          url: `/index.php/cod.get-accepted-legal-document?documentName=cookie_policy`,
          successEvent: ['GET_COOKIE_POLICY_ACCEPTANCE_SUCCESS'],
          errorEvent: ['GET_COOKIE_POLICY_ACCEPTANCE_ERROR'],
        }),
      };
    }
  );

  registerEventHandler(
    'GET_COOKIE_POLICY_ACCEPTANCE_SUCCESS',
    function getCookiePolicyAcceptanceSuccess(
      {
        globals: {
          trovitData: {
            googleAnalytics: { trackingId },
            user: { id: userId },
          },
        },
        cookie: { cookiePolicyRejected },
      },
      [{ acceptedAt }]
    ) {
      if (!acceptedAt) {
        const displayCookieDialog = typeof cookiePolicyRejected === 'undefined';
        return {
          ...state.set({
            isCookiePolicyDialogVisible: displayCookieDialog,
          }),
          googleAnalytics: { action: 'disable', payload: trackingId },
        };
      }

      return {
        ...state.set({
          isCookiePolicyDialogVisible: false,
        }),
        googleAnalytics: [
          { action: 'create', payload: trackingId },
          { action: 'set', payload: { name: 'userId', value: userId } },
          { action: 'send', payload: 'pageview' },
        ],
        ...cookies.remove({ key: 'cookiePolicyRejected' }),
      };
    },
    [globals.get('trovitData'), cookies.get('cookiePolicyRejected')]
  );

  registerEventHandler(
    'COOKIE_POLICY_WAS_ACCEPTED',
    function cookiePolicyWasAccepted({
      globals: {
        trovitData: { locale },
      },
    }) {
      return {
        ...http.post({
          url: '/index.php/cod.legal_documents_accept',
          body: {
            locale,
            documentNames: ['cookie_policy'],
          },
          successEvent: ['COOKIE_POLICY_ACCEPTED'],
          errorEvent: ['COOKIE_POLICY_ACCEPT_FAILED'],
        }),
      };
    },
    [globals.get('trovitData')]
  );

  registerEventHandler(
    'COOKIE_POLICY_ACCEPTED',
    function cookiePolicyAccepted() {
      return {
        ...state.set({
          isCookiePolicyDialogVisible: false,
        }),
      };
    }
  );

  registerEventHandler(
    'COOKIE_POLICY_ACCEPT_FAILED',
    function cookiePolicyAcceptanceFailed() {
      return {
        ...state.set({
          isCookiePolicyDialogVisible: false,
        }),
      };
    }
  );

  registerEventHandler(
    'COOKIE_POLICY_REJECTED',
    function cookiePolicyRejected() {
      return {
        ...cookies.set({
          key: 'cookiePolicyRejected',
          value: true,
        }),
        ...state.set({
          isCookiePolicyDialogVisible: false,
        }),
      };
    }
  );
}
