import { registerCoeffectHandler } from 'reffects';

export function registerCurrentDateTimeCoeffect(DateProvider = Date) {
  registerCoeffectHandler('currentDateTime', function currentDateTime() {
    return { currentDateTime: DateProvider.now() };
  });
}

export function registerTimeZoneOffsetCoeffect(DateProvider = Date) {
  registerCoeffectHandler('timeZoneOffset', function timeZoneOffset() {
    const dateTime = new DateProvider();
    return { timeZoneOffset: dateTime.getTimezoneOffset() };
  });
}
