import _ from 'underscore';
import _s from 'underscore.string';
import Backbone from 'backbone';

const SignalFilterView = Backbone.View.extend({
  tagName: 'li',
  template: _.template('<a href="#"><%= signalFilter.get(\'label\') %></a>'),
  name: 'signalFilterView',
  events: {
    click: 'applySignalFilter',
  },
  loadOrder: 9,
  initialize(options) {
    this.signalsCollection = options.signalsCollection;
  },
  render() {
    this.$el.html(
      this.template({
        signalFilter: this.model,
        _s,
      })
    );
    return this;
  },
  applySignalFilter() {
    this.signalsCollection.setSignalFilter(this.model).fetch();
  },
});
export default SignalFilterView;
