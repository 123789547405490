// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar {
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    width: 16.07rem;
    background: var(--palette-thribee-white);
}

.Sidebar.Sidebar--collapsed, .Sidebar.Sidebar--collapsed .SidebarCollapseButton  {
    width: 4.3rem;
}
`, "",{"version":3,"sources":["webpack://./js/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,wCAAwC;AAC5C;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".Sidebar {\n    display: flex;\n    position: relative;\n    height: 100%;\n    flex-direction: column;\n    width: 16.07rem;\n    background: var(--palette-thribee-white);\n}\n\n.Sidebar.Sidebar--collapsed, .Sidebar.Sidebar--collapsed .SidebarCollapseButton  {\n    width: 4.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
