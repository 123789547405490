import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/effect';
import 'jquery.cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../../components/library/Button/Button';

const MainNavigation = Backbone.View.extend({
  name: 'mainNavigation',
  events: {
    'autocompleteselect .js-search-source': '_changeToSource',
    'click .current-source .source-name strong': '_openSearchSource',
    'click #js-switch-user-view': 'changeUserView',
  },
  loadOrder: 7,
  _changeToSource(event, ui) {
    trovitApp.ajax.call({
      url: '/index.php/cod.switch_source',
      data: {
        switchTo: ui.item.partnerId,
      },
      success(json) {
        if (!json.success) {
          return false;
        }
        localStorage.removeItem('DateRange-1');
        trovitApp.gaTrackEvent('navigation', 'switch source');
        window.location.reload(true);
      },
    });
  },
  _openSearchSource(event) {
    this.$el.find('.js-search-source').focus();
    return false;
  },
  changeUserView(e) {
    e.preventDefault();
    $.cookie('clisim', $(e.currentTarget).data('mode'), {
      path: '/',
    });
    location.reload();
  },
  el: $('.js-main-navigation'),
  init() {
    const that = this;
    if (that.$el.find('.js-search-source').length) {
      const mySearchElement = that.$el.find('.js-search-source');
      that.searched = {};
      mySearchElement
        .autocomplete({
          minLength: 0,
          source(request, response) {
            if (that.searched[request.term]) {
              response(that.searched[request.term]);
            } else {
              trovitApp.ajax.call(
                {
                  url: '/index.php/cod.user_partners_json',
                  data: {
                    term: request.term,
                  },
                  success(json) {
                    json.data = _.map(json.data, function(partner) {
                      partner.label = $('<div />')
                        .html(partner.label)
                        .text();
                      return partner;
                    });
                    that.searched[request.term] = json.data;
                    response(that.searched[request.term]);
                    mySearchElement.focus();
                  },
                },
                {
                  refreshingKey: 'getUserPartners',
                }
              );
            }
          },
        })
        .focus(function() {
          $(this).autocomplete('search');
        })
        .removeAttr('readonly');
    }

    const upgradeButtonRoot = this.$('.js-upgrade-button').get(0);
    if (upgradeButtonRoot) {
      ReactDOM.render(
        <Button color="primary" variant="flat">
          {trovitApp.i18n('header_upgrade_cta')}
        </Button>,
        upgradeButtonRoot
      );
    }

    return true;
  },
});
trovitApp.widgets.loadWidget(new MainNavigation());
export default MainNavigation;
