import $ from 'jquery';
import Backbone from 'backbone';
import AccountManagerInboxTable from '../AccountManagerInbox/AccountManagerInboxTable';
import TopBarFilters from '../AccountManagerInbox/TopBarFilters';
import AjaxLoaderImage from '../../components/LoaderImage/AjaxLoaderImage';

const AccountManagerHome = Backbone.View.extend({
  el: $('#js-account-manager-home-dashboard'),
  name: 'accountManagerHome',
  events: {},
  loadOrder: 9,
  init() {
    this.ajaxLoaderImage = new AjaxLoaderImage({
      el: this.$('.js-data-loading'),
    });
    this.subViews = {};
    this.subViews.accountManagerInbox = new AccountManagerInboxTable({
      ajaxLoaderImage: this.ajaxLoaderImage,
    });
    this.subViews.topBarFilters = new TopBarFilters({
      el: this.$('#js_top_bar_filters'),
    }).render();
    return true;
  },
});
// Do not delete, due to the legacy implementation if this inicialization is deleted,
// the code will never be executed.
trovitApp.widgets.loadWidget(new AccountManagerHome());
export default AccountManagerHome;
