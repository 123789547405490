import 'sentry';
import 'main';
import './thribee.css';

// common
import bootstrap from 'bootstrap';
import sideBar from 'components/Sidebar/SidebarBuilder';
import cookiePolicyDialog from 'components/CookiePolicyDialog/CookiePolicyDialog';

// Backbone widgets
import 'Views/Widget/ContactUsForm';
import 'Views/Widget/FeedbackForm';
import 'Views/Widget/MainNavigation';

// Cannot be migrated yet due to 'bootstrap/js/dropdown' duplication
import 'Views/Widget/AccountManagerHome';

bootstrap();
sideBar();
cookiePolicyDialog();
