import React from 'react';
import composeClassNames from '../../../utils/composeClassNames';
import './SvgIcon.css';

export default function SvgIcon({
  children,
  className,
  width,
  height,
  spin,
  onClick,
}) {
  const spinClassName = spin ? 'SvgIcon--spin' : null;

  let style = {};

  if (width) {
    style = { ...style, width };
  }

  if (height) {
    style = { ...style, height };
  }

  return (
    <span
      className={composeClassNames(['SvgIcon', spinClassName, className])}
      style={style}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
    >
      {children}
    </span>
  );
}
