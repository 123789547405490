// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarCollapseButton {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    width: 16.07rem;
    bottom: 0;
    border: 0;
    background: var(--palette-gray-separator);
    cursor: pointer;
    padding: 0.57rem 0;
}

/* overriedes main.css .new-style button:hover rule */
.new-style button.SidebarCollapseButton:hover {
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./js/components/Sidebar/SidebarCollapseButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,SAAS;IACT,SAAS;IACT,yCAAyC;IACzC,eAAe;IACf,kBAAkB;AACtB;;AAEA,qDAAqD;AACrD;IACI,UAAU;AACd","sourcesContent":[".SidebarCollapseButton {\n    display: flex;\n    justify-content: flex-end;\n    position: fixed;\n    width: 16.07rem;\n    bottom: 0;\n    border: 0;\n    background: var(--palette-gray-separator);\n    cursor: pointer;\n    padding: 0.57rem 0;\n}\n\n/* overriedes main.css .new-style button:hover rule */\n.new-style button.SidebarCollapseButton:hover {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
