import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Loader from '../../components/LoaderImage/Loader';

const FeedbackForm = Backbone.View.extend({
  events: {
    'click .js-send-feedback': 'sendFeedback',
    'input #feedback-subject': 'validateSubject',
    'input #feedback-content': 'validateContent',
    'click .close': 'resetForm',
  },
  name: 'feedbackForm',
  loadOrder: 7,
  el: $('.js-feedback-modal'),
  init() {
    return this;
  },
  sendFeedback(event) {
    const feedbackData = {
      subject: this.$el.find('#feedback-subject').val(),
      content: this.$el.find('#feedback-content').val(),
    };
    if (this.validate(feedbackData)) {
      const loaderSubmit = new Loader({
        el: this.$el.find('.js-send-feedback'),
      });
      loaderSubmit.on();
      const that = this;
      trovitApp.ajax.call({
        url: '/index.php/cod.send_userfeedback',
        data: feedbackData,
        success(json) {
          if (json.success) {
            that.showSuccessScreen();
          } else {
            that.showErrorScreen();
          }
        },
        error() {
          that.showErrorScreen();
        },
        complete() {
          loaderSubmit.off();
        },
      });
    }
  },
  validate(data) {
    this.validateSubject();
    this.validateContent();
    return data.subject && data.content;
  },
  validateSubject() {
    this._validateField('#feedback-subject');
  },
  validateContent() {
    this._validateField('#feedback-content');
  },
  _validateField(fieldId) {
    if (this.$el.find(fieldId).val()) {
      this.$el.find(fieldId).removeClass('invalid');
    } else {
      this.$el.find(fieldId).addClass('invalid');
    }
  },
  showSuccessScreen() {
    const that = this;
    this.$el.find('.js-feedback-error').hide();
    this.$el.find('.js-feedback-form').fadeOut('normal', function() {
      that.$el.find('.js-feedback-success').fadeIn('normal');
    });
  },
  showErrorScreen() {
    const that = this;
    this.$el.find('.js-feedback-success').hide();
    this.$el.find('.js-feedback-form').fadeOut('normal', function() {
      that.$el.find('.js-feedback-error').fadeIn('normal');
    });
  },
  resetForm() {
    this.$el.find('.js-feedback-success').hide();
    this.$el.find('.js-feedback-error').hide();
    this.$el.find('#feedback-subject').val('');
    this.$el.find('#feedback-content').val('');
    this.$el.find('.js-feedback-form').fadeIn('normal');
  },
});
if (typeof trovitApp !== 'undefined') {
  trovitApp.widgets.loadWidget(new FeedbackForm());
}
export default FeedbackForm;
