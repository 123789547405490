import React from 'react';
import composeClassNames from '../../utils/composeClassNames';
import './SidebarItem.css';

function SidebarItem({ children, url, active, onClick }) {
  const classNames = composeClassNames([
    'SidebarItem',
    active ? 'SidebarItem--active' : null,
  ]);
  const content = (
    <button className={classNames} type="button" onClick={onClick}>
      {children}
    </button>
  );

  return url == null ? (
    content
  ) : (
    <a className="SidebarItem__wrapper" href={url}>
      {content}
    </a>
  );
}

export default SidebarItem;
